import fast1 from "../Images/Rectangle.png";
import fast2 from "../Images/Rectangle2.png";
import fast3 from "../Images/Rectangle3.png";
import project1 from "../Images/project1.png";
import project2 from "../Images/project2.png";
import project3 from "../Images/project3.png";
import project4 from "../Images/project4.png";

import setting from "../Images/setting.png";
import light from "../Images/light.png";
import box from "../Images/box.png";
import solution_img1 from "../Images/solution_img1.png";
import solution_img2 from "../Images/solution_img2.png";
import related_img1 from "../Images/related_img1.png";
import related_img2 from "../Images/related_img2.png";
import related_img3 from "../Images/related_img3.png";
import blog_detail_profile_img from "../Images/blog_detail_profile_img.png";
import solution_home_img from "../Images/solution_home_img.png";

import offering_serives_img3 from "../Images/offering_serives_img3.png";
import offering_services_img1 from "../Images/offering_services_img1.png";
import offering_services_img2 from "../Images/offering_services_img2.png";


import blog_detail_img from "../Images/blog_detail_img.png";



import work_img1 from '../Images/work_img1.png'
import work_img2 from '../Images/work_img2.png'
import work_img3 from '../Images/work_img3.png'


import original_ceo from "../Images/original_ceo.png";
import Saad_Rashid from "../Images/Saad_Rashid.jpeg";
import Hamna_Raziq from "../Images/Hamna_Raziq.png";
import arshid_manager from "../Images/arshid_manager.jpeg";
import aftab_marketing from "../Images/aftab_marketing.png";
import salim_site_operation from "../Images/salim_site_operation.png";


export const Slider = [
  {
    id: 1,
    title: "GREEN",
    title1: "WEND",
    title2: "ENERGY",
    subtitle: "EMPOWERING OUR FUTURE WITH SOLAR ENERGY",
    img: fast1,
    button: "Have Queries?",
  },
  {
    id: 3,
    title1: "MAGIC OF SOLAR ENERGY",
    subtitle: "WE PROVIDE CLEAN ENERGY, THE SOLAR ENERGY",
    img: fast2,
    button: "Free Survey",
  },
  {
    id: 2,
    title: "GET YOUR SOLAR SYSTEM TODAY",
    subtitle: "TODAY’S RESOURCE FOR A BRIGHTER TOMORROW",
    img: fast3,
    button: "Get Quote",
  },
];

export const data = [
  {
    img: setting,
    title: "Services",
    desc: "We provide FREE energy audit for your homes and offices and FREE site survey to determine the suitable solar solution according to your site and requirements.",
    learn: "Learn more",
  },

  {
    img: solution_home_img,
    title: "Solution",
    desc: "We design customized solutions keeping in mind your unique energy requirements and affordability. Our vast customer base across KP and ICT region is a testament for our tailor made solutions.",
    learn: "Learn more  ",
  },

  {
    img: box,
    title: "Products",
    desc: "GreenWend uses top of the line equipment and products from the world's top brands to ensure efficient and smooth operation of your solar energy system.",
    learn: "Learn more",
  },
];

export const projects = [
  {
    img: project1,
    title: "Installed 10kw system at Mr. Atif residency",
    learn: "Learn more",
  },
  {
    img: project2,
    title: "Complete setup of hybrid system at TriTech Solutions",
    learn: "Learn more",
  },
  {
    img: project3,
    title: "Installed 05kw system at Mr. Nasir farm house",
    learn: "Learn more",
  },

  {
    img: project4,
    title: "On-Grid System installed at Deans Residencia appartements",
    learn: "Learn more",
  },
];

export const team = [
  {
    img: original_ceo,
    title: "Mr. Zeeshan Syed",
    position: "CEO- Co-Founder",
  },
  {
    img: Saad_Rashid,
    title: "Mr. Saad Rashid",
    position: "CTO- Co-Founder",
  },
  {
    img: Hamna_Raziq,
    title: "Mr. Hamza Raziq",
    position: "TaleSales Manager",
  },
  {
    img: arshid_manager,
    title: "Mr. Arshid Ali",
    position: "Regional Manager",
  },
  {
    img: aftab_marketing,
    title: "Mr. Aftab Khan ",
    position: "Marketing Manager",
  },
  {
    img: salim_site_operation,
    title: "Mr. Saleem Khan",
    position: "Site Operation Manager",
  },
];

export const solution = [
  {
    id: 1,
    img: solution_img2,
    title: "On- Grid System",
    desc: "We provide FREE energy audit for your homes and offices and FREE site survey to determine the suitable solar solution according to your site and requirements",
  },

  {
    id: 2,
    img: solution_img1,
    title: "Semi-Hybrid System",
    desc: "It will only provide the power to the house and will also charge your batteries for backup. Pre-Tailored packeges accourding to your need are present but you can also customize them.",
  },
  {
    id: 3,
    img: solution_img2,
    title: "Hybrid System",
    desc: "This system will provide the power for your house and also charge battries for backup as well as the extra energy will be exported to the grid. Click here for more details and offers. ",
  },
];

export const Related = [
  {
    id: 1,
    img: related_img3,
    blog: "Blog Category",
    heading: "Lorem Ipsum is simply dummy text of the printing.",
    desc: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an has.",
    profile: blog_detail_profile_img,
    name: "Bloger Name",
    date: "11-March-2022 08 min read",
  },
  {
    id: 2,
    img: related_img2,
    blog: "Blog Category",
    heading: "Lorem Ipsum is simply dummy text of the printing.",

    desc: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an has.",
    profile: blog_detail_profile_img,
    name: "Bloger Name",
    date: "11-March-2022 08 min read",
  },
  {
    id: 3,
    img: related_img1,
    blog: "Blog Category",
    heading: "Lorem Ipsum is simply dummy text of the printing.",
    desc: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an has.",
    profile: blog_detail_profile_img,
    name: "Bloger Name",
    date: "11-March-2022 08 min read",
  },
];

export const portfolio_cards = [
  {
    id: 1,
    profile: aftab_marketing,
    name: "Bloger Name",
    date: "11-March-2022 ",
    desc: "  We provide FREE energy audit for your homes and offices and FREE site survey to determine the suitable solar solution according to your site and requirements and here are the ideas",
  },
  {
    id: 2,
    profile: aftab_marketing,
    name: "Bloger Name",
    date: "11-March-2022 ",
    desc: "  We provide FREE energy audit for your homes and offices and FREE site survey to determine the suitable solar solution according to your site and requirements and here are the ideas",
  },
  {
    id: 3,
    profile: aftab_marketing,
    name: "Bloger Name",
    date: "11-March-2022",
    desc: "  We provide FREE energy audit for your homes and offices and FREE site survey to determine the suitable solar solution according to your site and requirements and here are the ideas",
  },
  {
    id: 4,
    profile: aftab_marketing,
    name: "Bloger Name",
    date: "11-March-2022",
    desc: "  We provide FREE energy audit for your homes and offices and FREE site survey to determine the suitable solar solution according to your site and requirements and here are the ideas",
  },
  {
    id: 5,
    profile: aftab_marketing,
    name: "Bloger Name",
    date: "11-March-2022",
    desc: "  We provide FREE energy audit for your homes and offices and FREE site survey to determine the suitable solar solution according to your site and requirements and here are the ideas",
  },
];

export const services_cards = [
  {
    id: 1,
    img: offering_serives_img3,
    title: "Energy Audit & Survey",
    desc: "  We provide FREE energy audit for your homes and offices and FREE site survey to determine the suitable solar solution according to your site and requirements and here are the ideas",
  },
  {
    id: 2,
    img: offering_services_img2,
    title: "Solar System Design",
    desc: "It will only provide the power to the house and will also charge your batteries for backup. Pre-Tailored packages according to your need are present but you can also customize them.",
  },
  {
    id: 3,
    img: offering_services_img1,
    title: "Solar System Installation",
    desc: "This system will provide the power for your house and also charge batteries for backup as well as the extra energy will be exported to the grid. Click here for more details and offers.",
  },
  {
    id: 4,
    img: offering_services_img2,
    title: "Solar System Installation",
    desc: "This system will provide the power for your house and also charge batteries for backup as well as the extra energy will be exported to the grid. Click here for more details and offers.",
  },
  {
    id: 5,
    img: offering_services_img2,
    title: "Solar System Installation",
    desc: "This system will provide the power for your house and also charge batteries for backup as well as the extra energy will be exported to the grid. Click here for more details and offers.",
  },
];

export const offering_cards = [
  {
    id: 1,
    img: solution_img2,
    title: "On- Grid System",
    desc: "We provide FREE energy audit for your homes and offices and FREE site survey to determine the suitable solar solution according to your site and requirements",
  },

  {
    id: 2,
    img: solution_img1,
    title: "Semi-Hybrid System",
    desc: "It will only provide the power to the house and will also charge your batteries for backup. Pre-Tailored packeges accourding to your need are present but you can also customize them.",
  },
  {
    id: 3,
    img: solution_img2,
    title: "Hybrid System",
    desc: "This system will provide the power for your house and also charge battries for backup as well as the extra energy will be exported to the grid. Click here for more details and offers. ",
  },
];

export const image_slider = [
  {
    id: 1,
    img: blog_detail_img,
  },
  {
    id: 1,
    img: blog_detail_img,
  },
  {
    id: 2,
    img: blog_detail_img,
  },
  {
    id: 3,
    img: blog_detail_img,
  },
  {
    id: 4,
    img: blog_detail_img,
  },
];


export const work_slider = [
  {
    id: 1,
    img: work_img1,
    desc: "Our professional team do survey of your location firstly",
  },
  {
    id: 2,
    img: work_img2,
    desc: " Our skilled designers then design the most suitable solar system",
  },
  {
    id: 3,
    img: work_img3,
    desc: "Then our highly professionals install the system at your place",
  },
];