import React, { useEffect, useState } from "react";
import "../Styles/Main.css";
import { data, projects, Slider } from "../Config/Data";
import img from "../Images/Vector1.png";
import img1 from "../Images/research.png";
import img2 from "../Images/solution1.png";
import img3 from "../Images/warranty1.png";
import img4 from "../Images/compitative.png";
import img5 from "../Images/customer.png";
import about from "../Images/about.png";
import dot from "../Images/dot.png";
import Cards from "../Components/Cards";
import Projects from "../Components/Projects";
import vector12 from "../Images/vector12.png";
import { FaArrowRight } from "react-icons/fa";
import mobileImage from "../Images/mobileImage.PNG";
import star from "../Images/star.png";
import arrow from "../Images/arrow.png";
import Footer from "../Components/Footer";
import comma from "../Images/comma.png";
import Carousel from "react-bootstrap/Carousel";
// import testimony.png from "../Images/testimony.png";
import project1 from "../Images/project1.png";
import project2 from "../Images/project2.png";
import project3 from "../Images/project3.png";
import project4 from "../Images/project4.png";
import mobile_section from "../Images/mobile_section.svg";
import Input from "react-phone-number-input/input";
import CountUp from "react-countup";

import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const Main = () => {
  const [value, setValue] = useState();
  const [Count, setCount] = useState(0);
  const values = Slider[Count];

  const ForWard = () => {
    const next = Count + 1;
    const lent = Slider.length;
    if (next === lent) {
      setCount(0);
    } else {
      setCount(next);
    }
  };

  const BackWard = () => {
    const back = Count - 1;
    const lent = Slider.length;

    if (back < 0) {
      setCount(lent - 1);
    } else {
      setCount(back);
    }
  };

  useEffect(() => {
    const setting = setTimeout(() => {
      ForWard();
    }, 3000);
    return () => clearTimeout(setting);
  }, [Count]);

  // for projects section

  const [slide, setSlide] = useState(0);
  const curEle = projects[slide];

  const Forward = () => {
    const next = slide + 1;
    const lent = projects.length;
    if (next === lent) {
      setSlide(0);
    } else {
      setSlide(next);
    }
  };

  const Backward = () => {
    const back = slide - 1;
    const lent = projects.length;

    if (back < 0) {
      setSlide(lent - 1);
    } else {
      setSlide(back);
    }
  };

  useEffect(() => {
    const setting = setTimeout(() => {
      Forward();
    }, 3000);
    return () => clearTimeout(setting);
  }, [slide]);

  const checkMe = () => {
    const defaultCheck1 = document.querySelector("#defaultCheck1");
    const small = document.querySelector(".small");
    if (defaultCheck1.checked === true) {
      small.style.fontWeight = "500";
    } else {
      small.style.fontWeight = "300";
    }
  };

  return (
    <>
      {/* Slider Show */}
      <div className="main__image relative">
        <div className="slider__image">
          <img
            src={values.img}
            className="h-[100vh] w-[100%] relative"
            alt=""
          />
        </div>

        <div className="input_field">
          <input type="text" className="icon" placeholder="Search" />
        </div>
        <div className="heading_main">
          <div className="main__heading">
            <h2>
              <span className="text-[#5EA410]">{values.title}</span>
              <span className="text-[#202E86]">{values.title1}</span>
              <span className="text-[#F6951D]">{values.title2}</span>
            </h2>{" "}
            <div className="main__title">
              <p>{values.subtitle}</p>
              <button className="main__button">{values.button}</button>
            </div>
          </div>
        </div>
        {/* find this pexils <p className='absolute top-[656px]'>sdhfksdjfhksdhj</p> */}
      </div>
      {/* Home Services */}
      <div className="home__container">
        <div className="main__title-div">
          <p className="title__line">
            Why Choose{" "}
            <span className="avator_heading font-semibold">
              GREENWENDENERGY?
            </span>
          </p>
        </div>

        {/* First Choose  */}
        <div className="d-flex flex-wrap avator_items">
          <div className="container mt-2">
            <div className="item__class ">
              <div className="avator">
                <img src={img} className="image" alt="" />
              </div>
              <div className="paragh">
                <p>Professional Teams.</p>
              </div>
              <div className="avator">
                <img src={img1} className="image" alt="" />
              </div>
              <div className="paragh">
                <p>Continuous Research & Development.</p>
              </div>
            </div>
          </div>
          <div className="container mt-4">
            <div className="item__class ">
              <div className="avator">
                <img src={img2} className="image" alt="" />
              </div>
              <div className="paragh">
                <p>Tailer Made Solution.</p>
              </div>
              <div className="avator">
                <img src={img3} className="image" alt="" />
              </div>
              <div className="paragh">
                <p>Longest Warranties</p>
              </div>
            </div>
          </div>{" "}
          <div className="container mt-4">
            <div className="item__class ">
              <div className="avator">
                <img src={img4} className="image" alt="img" />
              </div>
              <div className="paragh">
                <p>Competitives Prices.</p>
              </div>
              <div className="avator">
                <img src={img5} className="image" alt="" />
              </div>
              <div className="paragh">
                <p>Best After Sale Services.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About Services */}
      <div className="about__div">
        <div className="container grid grid-two-column">
          <div className="about_image_section">
            <img src={about} className="about__image" alt="" />
            <div
              className="mession__div"
              data-aos="zoom-out-up"
              style={{ overflowX: "hidden", overflowY: "hidden" }}
            >
              <img src={comma} className=" mt-9 ml-7 comma_img" alt="" />
              <p className="paraghraph">
                Mission: To provide high quality cost effective products and
                enhance customer's satisfaction.
              </p>
            </div>
          </div>

          <div className="mobile_view_main_page">
            <p className="about__us">About Us</p>
            <p className="heading">
              What is
              <span className="about_heading text-[color: #3F3F3F]">
                <br />
                GreenWendEnergy?
              </span>
            </p>
            <p className="about__paragh">
              GreenWendEnergy (Pvt.) Ltd. is a privately held energy company
              that provide solar energy products and services as an alternate
              energy solution for diverse sectors including residential,
              commercial, industrial, and agricultural. From initial survey and
              software design to installation and maintenance, our teams manage
              every aspect of solar energy systems with highest standards of the
              solar energy industry.
            </p>
            <p className="about__more">
              <NavLink to="/about">
                {" "}
                More About Us <i className="fa fa-arrow-right"></i>
              </NavLink>
            </p>
          </div>
        </div>
      </div>
      {/* Services Design */}
      <div className="main_service_section">
        <div className="container">
          <div className="serviced">
            <h2 className="Serviced">
              Our <span className="text-[#5462BA] font-bold">Offerings</span>
            </h2>
            <p className="serviced__para">
              We are happy to use our expertise to work with you to develop a
              tailor-made solution for your challenge. Here are some highlights
              of our range of our offerings.
            </p>
          </div>
        </div>
        {/* Services Cards */}
        <div className=" main_service_card container-fluid grid grid-three-column">
          <Cards />
          {/* {data.map((it) => {
            return (
              <div>
                <Cards
                  ImgSrc={it.img}
                  Title={it.title}
                  Desc={it.desc}
                  Learn={it.learn}
                />
              </div>
            );
          })} */}
        </div>
      </div>
      {/* Our Achievements  */}
      <div className="achive__image">
        <h2 className="our__head">
          Our <span className="font-bold">Achievements</span>
        </h2>

        <div className="container grid grid-four-column achivements_mobile ">
          <div className="flex flex-wrap justify-center h-24 w-80">
            <p className="our__para">
              <CountUp enableScrollSpy start={0} end={7} duration={1} />+{" "}
              <span className="kilometer">MW</span>
            </p>
            <p className="our__title">Installed Capacity</p>
          </div>

          <div className="flex flex-wrap justify-center h-24 w-80">
            <p className="our__para">
              <CountUp enableScrollSpy start={0} end={25.2} duration={1} />{" "}
              <span className="kilometer">million</span>
            </p>
            <p className="our__title">Units Generated</p>
          </div>

          <div className="flex flex-wrap justify-center h-24 w-80">
            <p className="our__para">
              <CountUp enableScrollSpy start={0} end={17869} duration={1} />
              <span className="kilometer">Metric Tons</span>
            </p>
            <p className="our__title">Carbon Dioxide Reduced</p>
          </div>

          <div className="flex flex-wrap justify-center h-24 w-80">
            <p className="our__para">
              <CountUp enableScrollSpy start={0} end={3475} duration={1} />{" "}
              <span className="kilometer">Houses</span>
            </p>
            <p className="our__title"> Solarized</p>
          </div>
        </div>

        {/* for mobile view */}
      </div>
      <br />
      <br />
      {/* Our Projects */}
      <div className="main_project_sections mt-4">
        <h2 className="our__projects mb-5 ">
          <span> Our </span>{" "}
          <span className="text-[#5462BA] fw-bold">Projects</span>
        </h2>
        {/* <div className="container gird grid-four-column"> */}
        <div className="container-fluid grid grid-four-column  project_desktop_view">
          {projects.map((it) => {
            return (
              <>
                <Projects ImgSrc={it.img} Title={it.title} Learn={it.learn} />
              </>
            );
          })}
        </div>
      </div>
      {/* </div> */}

      {/* for mobile view */}
      <div className="projects_mobile_view">
        <div className="container">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {projects.map((curEle) => {
              return (
                <>
                  <SwiperSlide>
                    <div className="mt-4  project__div">
                      <div className="project__image">
                        <img src={curEle.img} alt="img" className="img-fluid" />
                      </div>
                      <p className="projects__title">{curEle.title}</p>
                      <p className="projects__learn">
                        {curEle.learn} <i className="fa fa-arrow-right"></i>
                      </p>
                    </div>
                  </SwiperSlide>
                </>
              );
            })}
          </Swiper>
        </div>
      </div>

      {/* Testimony Added */}
      <div className="testimony">
        <h2 className="testimony__head">Testimony</h2>
        <div
          className="vector__image"
          data-aos="zoom-out-up"
          style={{ overflowX: "hidden", overflowY: "hidden" }}
        >
          <img src={vector12} alt="" />
        </div>

        <div className="testimony_main">
          <div className="user__review">
            <div
              className="testimony_star"
              data-aos="zoom-out-up"
              style={{ overflowX: "hidden", overflowY: "hidden" }}
            >
              <img
                src={star}
                className="h-[45.33px] w-[45.67px] text-[#F6951D] testimony_star_mobile"
                alt=""
              />
              <img
                src={star}
                className="h-[45.33px] w-[45.67px] text-[#F6951D] testimony_star_mobile"
                alt=""
              />
              <img
                src={star}
                className="h-[45.33px] w-[45.67px] text-[#F6951D] testimony_star_mobile"
                alt=""
              />
              <img
                src={star}
                className="h-[45.33px] w-[45.67px] text-[#F6951D] testimony_star_mobile"
                alt=""
              />
              <img
                src={star}
                className="h-[45.33px] w-[45.67px] text-[#F6951D] testimony_star_mobile"
                alt=""
              />
            </div>
            <p>
              I recently had the pleasure of working with GreenWend Energy to
              install a 25kW grid-tied system at my home. From start to finish ,
              I had a great experience. The team was professional,
              knowledgeable, and attentive to my needs. I highly recommend
              GreenWend Energy.
            </p>

            <div className="user_name  mt-3 mb-5">
              <p>
                Chief Justice (R) Dost Muhammad Khan, Bahria Enclave Islamabad.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile Application Page Added */}
      <div className="main_mobile_section">
        <div className=" mobile_section grid grid-two-column">
          <div
            className="mobile__image"
            data-aos="fade-down-right"
            style={{ overflowX: "hidden", overflowY: "hidden" }}
          >
            <img src={mobileImage} alt="img" />
          </div>

          <div
            className="mobile_para_main"
            data-aos="fade-down-left"
            style={{ overflowX: "hidden", overflowY: "hidden" }}
          >
            <h2 className="mobile__head">
              Our
              <span className="font-bold text-[#5462BA] flex">
                Mobile Application
              </span>
            </h2>

            <p className="mobile__para">
              Greenwend Energy Pvt Ltd is having one of the best and user
              friendly mobile application throughout the solar industry for
              monitoring your solar plant and you can also control your solar
              plant with this application. You can download our application by
              just clicking the link bellow.
            </p>

            <p className="mobile__download">
              <button>
                {" "}
                Download app <i className="fa fa-arrow-right"></i>
              </button>
            </p>
          </div>
        </div>
      </div>
      {/* contact us */}
      <div className="cantact__us mb-5 mt-5">
        <h2 className="cantact__head mt-5">Contact Us </h2>
        <p className="contact__para">
          Let’s discuss{" "}
          <span className="solor_project">your solar project</span>
        </p>
        {/* Form Contact */}
        <div className="container">
          <form>
            <div className="flex1 d-flex">
              <div className="form-group">
                <label for="exampleInputEmail1">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Name"
                />
              </div>
              <div className="form-group">
                <label for="exampleInputPassword1">
                  Company Name (Optional)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Company Name"
                />
              </div>
            </div>

            <div className="flex1 d-flex mt-3">
              <div className="form-group ">
                <label for="exampleInputEmail1">Contact No.</label>
                <Input
                  country="PK"
                  international
                  withCountryCallingCode
                  value={value}
                  onChange={setValue}
                  className="countrycode"
                />
              </div>
              <div className="form-group ">
                <label for="exampleInputPassword1">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Email"
                />
              </div>
            </div>

            <div className="textarea  mt-5">
              <div className="checkbox">
                <input
                  onClick={checkMe}
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <small className="small">Subscribe to our NewsLetter</small>
              </div>
              <label
                for="exampleFormControlTextarea1"
                className="textarea_mobile_view"
              >
                Message
              </label>
              <textarea
                className="form-control "
                id="exampleFormControlTextarea1"
                rows="8"
                placeholder="Type your message here"
              ></textarea>
            </div>
            <div className="buttons">
              <button type="submit" className="mt-4 btn mb-7">
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Main;
